import { initializeApp } from 'firebase/app';
import { getAuth, signOut } from 'firebase/auth';
import { getMessaging, isSupported } from 'firebase/messaging';

import { goto } from '$app/navigation';
import userStore from '$lib/stores/userStore';

import { env } from '../../env';

const app = initializeApp(env.firebase);

export const auth = getAuth(app);

export const messagingPromise = (async () => {
  try {
    return (await isSupported()) ? getMessaging(app) : undefined;
  } catch (err) {
    console.log(err);
    return undefined;
  }
})();

export async function signOutWithRedirect(
  isSignUp = true,
  token: string | null = null,
  originUrl: string | null = null,
  tokenKey = 'token'
) {
  localStorage.removeItem('currentOrganization');
  userStore.set({ user: null });
  await signOut(auth);
  await goto(
    `/auth/${isSignUp ? 'signUp' : 'login'}${token ? `?${tokenKey}=` + token : ''}${
      originUrl ? '&originUrl=' + encodeURIComponent(originUrl) : ''
    }`
  );
}
